import React from 'react';
import { LastLocationProvider } from 'react-router-last-location';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import TermsOfService from './components/TermsOfService/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import LandingPage from './components/LandingPage/LandingPage';
import API from './components/API'

function App(): JSX.Element {
  return (
    <div className='App'>
      <BrowserRouter>
        <LastLocationProvider>
          <Switch>
            <Route path='/' exact render={() => <LandingPage/>}/>
            <Route path='/terms-of-service' exact render={() => <TermsOfService/>}/>
            <Route path='/privacy-policy' exact render={() => <PrivacyPolicy/>}/>
            <Route path='/api' exact component={() => <API/>}/>
            <Route path='/*' exact component={() => <Redirect to={'/'}/>}/>
          </Switch>
        </LastLocationProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
