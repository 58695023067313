import React from 'react';
import { withRouter } from 'react-router-dom';
import { emptyProps } from '../../../interfaces/interfaces';

function GetStartedBtn(props: emptyProps) {
  return (
    <div className='landing-page-gs-btn-container'>
      <a href='https://otakugifs.xyz/api' rel="noreferrer" target='_blank'>
        Get Started
      </a>
    </div>
  )
}

export default withRouter(GetStartedBtn);