import React from 'react';
import { withRouter } from 'react-router-dom';
import GetStartedBtn from '../Reuseable/Buttons/GetStartedBtn';
import ScrollToTop from '../Reuseable/Buttons/ScrollToTop';
import Footer from '../Reuseable/Footer/Footer';
import WaveAnimation from '../Reuseable/WaveAnimation/WaveAnimation';
import About from './comps/About';
import Header from './comps/Header';
import Main from './comps/Main';
import Features from './comps/Features';
import Faq from './comps/Faq';
import CookiePopup from "../Reuseable/CookiePopup/CookiePopup";
import Ads from './comps/Ads';

function LandingPage(): JSX.Element {

  const aboutRef = React.useRef<HTMLElement>(null);
  const featuresRef = React.useRef<HTMLElement>(null);
  const faqRef = React.useRef<HTMLElement>(null);
  const partnersRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <WaveAnimation />
      <ScrollToTop />

      <Header refs={{ aboutRef, featuresRef, faqRef, partnersRef }} />
      <Main refs={{ aboutRef }} />

      <About refs={{ aboutRef }} />
      <GetStartedBtn />

      <Features refs={{ featuresRef }} />
      <GetStartedBtn />

      <Faq refs={{ faqRef }} />
      <GetStartedBtn />

      <Ads refs={{ partnersRef }} />

      <Footer />
      <CookiePopup />
    </>
  )
}

export default withRouter(LandingPage);
