import React from 'react';
import { landingPageFeaturesProps } from '../../../interfaces/interfaces';
import svg1 from '../../../assets/features.1.svg';
import svg2 from '../../../assets/features.2.svg';
import svg3 from '../../../assets/features.3.svg';
import svg4 from '../../../assets/features.4.svg';
import svg5 from '../../../assets/features.5.svg';
import svg6 from '../../../assets/features.6.svg';

function Features(props: landingPageFeaturesProps): JSX.Element {

  const { featuresRef } = props.refs;

  return (
    <section className='landing-page-features' aria-label='features' ref={featuresRef}>
      <h2 id='features'>Features</h2>
      <div className='card'>
        <div>
          <h3>High Quality</h3>
          <p>At least 450x200px dimensions, with consistent aspect ratios.</p>
        </div>
        <div className='graphic'>
          <img src={svg1} alt="dimensions, At least 450x200px dimensions." />
        </div>
      </div>
      <div className='card'>
        <div>
          <h3>Small Filesize</h3>
          <p>Carefully compressed and optimized.</p>
          <p>Average of 704KBs per GIF.</p>
        </div>
        <div className='graphic'>
          <img src={svg2} alt="filesize, Carefully compressed and optimized." />
        </div>
      </div>
      <div className='card'>
        <div>
          <h3>Large Collection</h3>
          <p>Over 1000 GIFs, and we keep adding!</p>
        </div>
        <div className='graphic'>
          <img src={svg3} alt="Large Collection, Over 880 GIFs" />
        </div>
      </div>
      <div className='card'>
        <div>
          <h3>Over 68 Reactions</h3>
          <p>Express more than 68 emotions.</p>
        </div>
        <div className='graphic'>
          <img src={svg4} alt="Over 68 Reactions, Express more than 68 emotions" />
        </div>
      </div>
      <div className='card'>
        <div>
          <h3>CDN Boosted</h3>
          <p>Users will see their GIFs load almost instantly.</p>
        </div>
        <div className='graphic'>
          <img src={svg5} alt="CDN Boosted, Users will see their GIFs load almost instantly" />
        </div>
      </div>
      <div className='card'>
        <div>
          <h3>Discord Banlist</h3>
          <p>Remove shady users from your guild.</p>
        </div>
        <div className='graphic'>
          <img src={svg6} alt="Discord Banlist, Remove shady users from your guild" />
        </div>
      </div>
    </section>
  )
}

export default Features;
