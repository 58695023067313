import React from 'react';

function PrivacyPolicy(): JSX.Element {
  return (
    <div className='pp-container'>
      <h1>Privacy Policy</h1>

      <p>
        Information we collect may include but not be limited to email, your email content sent at "support@otakugifs.xyz", your IP.
      </p>
      <p>
        We collect your Personal Data directly when you provide us with Personal Data when you visit our Sites,
        use our applications, and/or make a comment or enquiry or contact our customer services.
      </p>

    </div>
  );
}

export default PrivacyPolicy;
