import React from 'react';
import { landingPageFaqProps } from '../../../interfaces/interfaces';

function Faq(props: landingPageFaqProps): JSX.Element {

  const {faqRef} = props.refs;

  return (
    <section className='landing-page-faq' ref={faqRef}>
      <h2>FAQ</h2>
      <div className='field'>
        <p className='question'>What are the reactions?</p>
        <p className='answer'>airkiss, angrystare, bite, bleh, blush, brofist, celebrate, cheers, clap, confused, cool, cry, cuddle, dance, drool, evillaugh, facepalm, handhold, happy, headbang, hug, kiss, laugh, lick, love, mad, nervous, no, nom, nosebleed, nuzzle, nyah, pat, peek, pinch, poke, pout, punch, roll, run, sad, scared, shout, shrug, shy, sigh, sip, slap, sleep, slowclap, smack, smile, smug, sneeze, sorry, stare, stop, surprised, sweat, thumbsup, tickle, tired, wave,
          wink, woah, yawn, yay, yes.</p>
      </div>
      <div className='field'>
        <p className='question'>How often do you add new reactions and GIFs?</p>
        <p className='answer'>We have a list of reactions that we want to add but it all comes down to how many GIFs of that reaction we can find. We are always looking for new ones to add!</p>
      </div>
      <div className='field'>
        <p className='question'>Should I use GIF, AVIF or WebP format?</p>
        <p className='answer'>It all comes down to the platform and your users. GIF works in the browser and platforms like Discord. WebP, a better format that GIF, is not supported on Discord but is favoured in any browser. AVIF, the newest of them all, has a tiny filesize but slow encoding and decoding speeds. Also is not supported on Discord and it's only supported on chromium based browsers.</p>
      </div>
      <div className='field'>
        <p className='question'>How can I contribute?</p>
        <p className='answer'>You can recommend new reactions and GIFs, help newbies with implementing OtakuGIFs in their language, and interact with us, all in our Discord.</p>
      </div>
    </section> 
  )
}

export default Faq;
